import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkCopy, Modal } from 'Components';
import { CONSTANTS, CONTENT_PROP, IS_FOR_EXPORT_PDF, IS_FOR_EXPORT_WORD } from 'Constants';
import style from './ImageView.module.scss';
import { debounce, detectViewMode } from 'Utils';
import icons from 'Assets/svgSprite.svg';

const MIN_CONTENT_SIDE_PADDING = '20px';

/**
 * ImageView.jsx
 *
 * @summary Component for images that allow to view larger image.
 * - Adds a popup modal for veiwing large image
 * - Modal can be closed by clicking 'close', clicking 'backdrop', pressing 'escape'
 *
 * @param {Object} props - Component props.
 * @prop {String} props.id - Image id.
 * @prop {String} props.systemId - System id returned from API generated by Kontent.
 * @prop {String} props.imgSrc - Image src url.
 * @prop {String} props.description - Image description.
 * @prop {String} props.altText - Image alt text.
 * @prop {Number} props.height - Image height.
 * @prop {Number} props.width - Image width.
 * @prop {String} [props.padding] - Image padding.
 * @prop {String} [props.alignment] - Image alignment (accepted enum values from CONTENT_PROP.ALIGNMENT constants).
 * @prop {String} [props.wrapText] - Wrap text around image (accepted enum values from CONTENT_PROP.WRAP_TEXT constant).
 * @prop {Boolean} [props.disabledLargerImage] - Disable ability to open image in larger view
 */
export default function ImageView({ id, systemId, imgSrc, description, altText, height, width, padding, alignment, wrapText, disabledLargerImage }) {
  const [largeImageIsOpen, setLargeImageIsOpen] = useState(false);
  const [viewMode, setViewMode] = useState(detectViewMode());

  const openLargeImageModal = () => {
    setLargeImageIsOpen(true);
  };

  const closeLargeImageModal = () => {
    setLargeImageIsOpen(false);
  };

  useEffect(() => {
    if (largeImageIsOpen) {
      // hide scrollbar of entire page when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [largeImageIsOpen]);

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  const figureStyling = {};

  // Add styling to image with given alignment, wrap-text
  if (viewMode !== CONSTANTS.VIEW_MODE.MOBILE) {
    if (alignment === CONTENT_PROP.ALIGNMENT.LEFT_ALIGN) {
      figureStyling.marginRight = 'auto';
      if (wrapText === CONTENT_PROP.TEXT_WRAP.WRAP_AROUND) {
        figureStyling.maxWidth = '50%';
        figureStyling.marginRight = MIN_CONTENT_SIDE_PADDING; // add extra margin to right if left align (so that there is spacing for wrapped text)
        figureStyling.float = 'left';
      }
    } else if (alignment === CONTENT_PROP.ALIGNMENT.RIGHT_ALIGN) {
      figureStyling.marginLeft = 'auto';
      if (wrapText === CONTENT_PROP.TEXT_WRAP.WRAP_AROUND) {
        figureStyling.maxWidth = '50%';
        figureStyling.marginLeft = MIN_CONTENT_SIDE_PADDING; // add extra margin to right if left align (so that there is spacing for wrapped text)
        figureStyling.float = 'right';
      }
    } else if (alignment === CONTENT_PROP.ALIGNMENT.CENTER_ALIGN) {
      figureStyling.marginRight = 'auto';
      figureStyling.marginLeft = 'auto';
    }
  }

  const imageStyling = {
    padding: padding ? `${padding}px` : '',
    aspectRatio: `${width / height}`,
    width: `${width}px`,
  };

  return (
    <>
      {largeImageIsOpen && (
        <Modal closeModal={closeLargeImageModal} hasCloseModalButton={true}>
          {
            <figure className={[style.imageView, style.modal].join(' ')}>
              <div className={style['modal-image']}>
                <img src={imgSrc} alt={altText} />
                <figcaption className={style.open}>{description} </figcaption>
              </div>
            </figure>
          }
        </Modal>
      )}
      {
        /** Only add image styling to outer figure when wrap text is checked */
        <>
          {IS_FOR_EXPORT_PDF && (
            <span className="invisibleForPdfExport" aria-hidden>
              {systemId}
            </span>
          )}
          {IS_FOR_EXPORT_WORD && (
            <span className="invisibleForWordExport" aria-hidden>
              {systemId}
            </span>
          )}
          <div className="linkCopyWrapper">
            <figure id={id} data-system-id={systemId} className={style.imageView} style={figureStyling}>
              {/** Add image styling to inner figure which will align left/right/center without wrapping text (when wrap text is not checked) */}
              {!disabledLargerImage ? (
                <button className={[style.clickableImage, 'noFill'].join(' ')} onClick={openLargeImageModal} aria-label="Open larger image" title="Open larger image">
                  <img style={imageStyling} src={imgSrc} alt={altText} />
                  <span className={style.expandIcon}>
                    <svg>
                      <use href={`${icons}#expand`} />
                    </svg>
                  </span>
                </button>
              ) : (
                <img style={imageStyling} src={imgSrc} alt={altText} />
              )}
              {description && <figcaption>{description}</figcaption>}
            </figure>
            <LinkCopy targetId={id} />
          </div>
        </>
      }
    </>
  );
}

ImageView.propTypes = {
  id: PropTypes.string.isRequired,
  systemId: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  alignment: PropTypes.string,
  padding: PropTypes.string,
  wrapText: PropTypes.string,
  disabledLargerImage: PropTypes.bool,
};
