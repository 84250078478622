import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { authRequest, msalConfig } from 'authConfig.js';
import { IS_FOR_EXPORT_PDF, IS_FOR_EXPORT_WORD, IS_PREVIEW_MODE, IS_PUBLISHED_APP, PATHS, ROUTES } from 'Constants';
import { setAxiosDefaults } from 'Services';
import reportWebVitals from 'reportWebVitals';
import './index.css';

/**
 * if production build, set to not show any logs in console.
 * only disable `log`, `info` and `warn`
 */
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
}

function Protector() {
  const { instance, inProgress, accounts } = useMsal();
  const [authenticated, updateAuthenticated] = useState(false);
  const account = useAccount(accounts[0] || {});
  const isDARTLandingPage = window.location.pathname === `/${PATHS.CONTRIBUTER_LANDING_PAGE}`;

  const getToken = async () => {
    if (inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: authRequest.scopes,
        account,
      };

      try {
        await instance.acquireTokenSilent(accessTokenRequest);
        updateAuthenticated(true);
        setAxiosDefaults();
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(() => {
              // Acquire token interactive success
              updateAuthenticated(true);
              setAxiosDefaults();
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.error(error);
            });
        }
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (!isDARTLandingPage) {
      getToken();
    }
  }, [instance, accounts, inProgress]);

  if (isDARTLandingPage) {
    /**
     * If path is custom landing page, we do not need to authenticate.
     * As soon as user navigate to other path, FE will try to authenticate and after authenticated, if
     * user navigate back to landing page, FE does not care if user is authenticated to view the landing
     * page or not.
     */
    return <RouterProvider router={createBrowserRouter(ROUTES)} />;
  }

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
      {authenticated && <RouterProvider router={createBrowserRouter(ROUTES)} />}
    </MsalAuthenticationTemplate>
  );
}

const root = ReactDOM.createRoot(document.getElementById('TPAppHolder'));

if (IS_PUBLISHED_APP) {
  const googleScript = document.createElement('script');
  googleScript.async = true;
  googleScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-2TM9QN0BYG';

  const googleFunctionScript = document.createElement('script');
  googleFunctionScript.appendChild(
    document.createTextNode("window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-2TM9QN0BYG');"),
  );
  document.head.appendChild(googleScript);
  document.head.appendChild(googleFunctionScript);
}

if (IS_PREVIEW_MODE && !IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD) {
  const msalInstance = new PublicClientApplication(msalConfig);

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  });

  root.render(
    <MsalProvider instance={msalInstance}>
      <Protector></Protector>
    </MsalProvider>,
  );
} else {
  // for non preview app and FE app used for BE export AR to PDF
  setAxiosDefaults();

  root.render(<RouterProvider router={createBrowserRouter(ROUTES)} />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
