import React, { Suspense, useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import style from './DataSets.module.scss';
import {
  AlphabetGroupFilterList,
  DataSetsFilterAccordion,
  DataSetsHeader,
  DataSetsTable,
  DropdownSelect,
  FormField,
  GroupedFilterList,
  HorizontalScroll,
  ListSelect,
  Loading,
  MessageBox,
  SearchBar,
} from 'Components';
import { BODY_TYPE, DATA_SETS_URL_FILTER_GROUPS, DATA_SET_URL_CONSTANTS, DATA_TABLE_TYPES, MESSAGE_BOX_TYPE, PATHS, SOMETHING_WENT_WRONG, URL_QUERY_KEYS } from 'Constants';
import { Await, useRouteLoaderData, useSearchParams, useLocation } from 'react-router-dom';
import { convertObjectToArray, sortByObjectProperty } from 'Utils';
import { Link } from 'react-router-dom';
import { getDataSets, getDataTableTemplate } from 'Services/Delivery';
import { constructCSVFixedTable, constructCSVAppendTable } from 'Utils/DataSets/DataSetsUtils';
import { DataSetsLoading } from 'Pages';

/**
 * DataSets.jsx
 *
 * @summary This component is page view for Data sets (tables) page.
 *
 */
function DataSets() {
  const { dataSets } = useRouteLoaderData(PATHS.DATA_SETS.ID);
  const [filteredDataSetList, setFilteredDataSetList] = useState({});
  const [dataSetFilterSelection, setDataSetFilterSelection] = useState({ groupedDataSetsFilters: [], dataSetFilters: {}, dataSetFilterSelectAll: false });
  const [showOnlySelectedDataSets, setShowOnlySelectedDataSets] = useState(false);
  const [atLeastOneYearSelected, setAtLeastOneYearSelected] = useState(false);
  const [reportingYearFilterSelection, setReportingFilterSelection] = useState({ reportingYearFilters: [], selectAllReportingYears: false });
  const [entityFilterSelection, setEntityFilterSelection] = useState({
    bodyTypeFilters: [],
    selectAllBodyTypes: false,
    portfolioFilters: [],
    selectAllPortfolios: false,
    entityFilters: [],
    selectAllEntities: false,
  });
  const [showOnlySelectedEntities, setShowOnlySelectedEntities] = useState(false);
  const [additionalFilterSelection, setAdditionalFilterSelection] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({ dataSetFilters: [], reportingYearFilters: [], entityFilters: [], additionalFilters: [] });
  const [dataSetTables, setDataSetTables] = useState([]);
  const [noDataSetData, setNoDataSetData] = useState([]);
  const [exportIndividualCSVData, setExportIndividualCSVData] = useState([]);
  const exportCSVDownloadButtonRef = useRef([]);
  const exportCsvRefs = useRef([]);
  const [urlParams, setUrlParams] = useSearchParams();
  const { pathname } = useLocation();
  const [expandAllCells, setExpandAllCells] = useState([]);
  const [fetchDataSetsLoading, setFetchDataSetsLoading] = useState(false);

  // DATA SET FILTERS

  useEffect(() => {
    if (!isSomeDataSetsFilterSelected()) {
      setShowOnlySelectedDataSets(false);
    }
  }, [dataSetFilterSelection]);

  // Selects all data set filter options
  const selectAllDataSetFilters = () => {
    const newFilters = { ...dataSetFilterSelection.dataSetFilters };

    Object.keys(newFilters).forEach((key) => {
      newFilters[key].forEach((option) => {
        if (convertObjectToArray(filteredDataSetList).some((dataSetFilter) => dataSetFilter.returnValue === option.returnValue)) {
          option.selected = !dataSetFilterSelection.dataSetFilterSelectAll;
        }
      });
    });
    setDataSetFilterSelection({ ...dataSetFilterSelection, dataSetFilters: newFilters, dataSetFilterSelectAll: !dataSetFilterSelection.dataSetFilterSelectAll });
  };

  // Selects specific data set filter
  const selectDataSetFilter = (selectedOption) => {
    const newFilters = { ...dataSetFilterSelection.dataSetFilters };

    Object.keys(newFilters).forEach((key) => {
      newFilters[key].forEach((option) => {
        if (selectedOption.returnValue === option.returnValue) {
          option.selected = !option.selected;
        }
      });
    });
    setDataSetFilterSelection({ ...dataSetFilterSelection, dataSetFilters: newFilters });
  };

  const isAllDataSetsFilterSelected = () => {
    return Object.keys(dataSetFilterSelection.dataSetFilters).every((filterGroup) => {
      return dataSetFilterSelection.dataSetFilters[filterGroup].every((filterValue) => filterValue.selected === true);
    });
  };

  const isSomeDataSetsFilterSelected = () => {
    const someSelected = Object.keys(dataSetFilterSelection.dataSetFilters).some((filterGroup) => {
      return dataSetFilterSelection.dataSetFilters[filterGroup].some((filterValue) => filterValue.selected === true);
    });
    return someSelected;
  };

  // Selects grouped data set option, also updates data set list based on grouped selection
  const selectGroupedDataSetOption = (selectedOption) => {
    const groupedDataSets = [...dataSetFilterSelection.groupedDataSetsFilters];
    const dataSetFilters = { ...dataSetFilterSelection.dataSetFilters };
    groupedDataSets.forEach((option) => {
      if (option.returnValue === selectedOption.returnValue) {
        // Update all data set filter list when grouped selection is chosen
        Object.keys(dataSetFilters).forEach((key) => {
          dataSetFilters[key].forEach((dataSetOption) => {
            if (selectedOption.returnValue === dataSetOption.dataSetGroup) {
              dataSetOption.selected = !option.selected;
            }
          });
        });
        // update grouped selection checkbox
        option.selected = !option.selected;
      }
    });
    setDataSetFilterSelection({ ...dataSetFilterSelection, dataSetFilters, groupedDataSetsFilters: groupedDataSets });
  };

  const filterDataSetValueSearch = (newValue) => {
    const filteredDataSetGroup = {};
    Object.keys(dataSetFilterSelection.dataSetFilters).forEach((dataSetGroup) => {
      const fitleredList = dataSetFilterSelection.dataSetFilters[dataSetGroup].filter((option) => {
        if (option.display) {
          return option.display.toLowerCase().includes(newValue.toLowerCase());
        }
        return false;
      });
      if (fitleredList?.length > 0) {
        filteredDataSetGroup[dataSetGroup] = fitleredList;
      }
    });

    setFilteredDataSetList(filteredDataSetGroup);
  };

  const clearDataSetFilters = () => {
    const updatedGroupedDataSets = setSelectedValue([...dataSetFilterSelection.groupedDataSetsFilters], false);
    const updatedDataSets = { ...dataSetFilterSelection.dataSetFilters };
    Object.keys(updatedDataSets).forEach((key) => {
      updatedDataSets[key].forEach((option) => {
        option.selected = false;
      });
    });
    setDataSetFilterSelection({ groupedDataSetsFilters: updatedGroupedDataSets, dataSetFilters: updatedDataSets, dataSetFilterSelectAll: false });
    setShowOnlySelectedDataSets(false);
  };

  const filterSelectedDataSets = () => {
    const filteredDataSets = {};
    Object.keys(dataSetFilterSelection.dataSetFilters).forEach((key) => {
      filteredDataSets[key] = dataSetFilterSelection.dataSetFilters[key].filter((filterOption) => filterOption.selected === true);
    });
    return filteredDataSets;
  };

  // REPORTING YEAR, ENTITIES OR COMPANIES FILTERS
  useEffect(() => {
    if (!atLeastOneEntitySelected()) {
      setShowOnlySelectedEntities(false);
    }
  }, [entityFilterSelection]);

  useEffect(() => {
    if (atLeastOneReportingYearSelected()) {
      setAtLeastOneYearSelected(true);
    } else {
      setAtLeastOneYearSelected(false);
    }
  }, [reportingYearFilterSelection]);

  const selectAllReportingYears = () => {
    const updatedReportingYearOptions = [...reportingYearFilterSelection.reportingYearFilters];
    updatedReportingYearOptions.forEach((option) => {
      option.selected = !reportingYearFilterSelection.selectAllReportingYears;
    });
    setReportingFilterSelection({ reportingYearFilters: updatedReportingYearOptions, selectAllReportingYears: !reportingYearFilterSelection.selectAllReportingYears });
  };

  const selectReportingYearDropdown = (selectedOption) => {
    const updatedSelection = [...reportingYearFilterSelection.reportingYearFilters];
    const foundSelectedOption = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    if (foundSelectedOption) {
      foundSelectedOption.selected = !foundSelectedOption.selected;
    }
    setReportingFilterSelection({ ...reportingYearFilterSelection, reportingYearFilters: updatedSelection });

    // Reset entity selection only if it has been removed from list (since reporting year changes the list of entities to only show active ones)
    const selectedBodyTypes = entityFilterSelection.bodyTypeFilters.filter((filter) => filter.selected === true) || [];
    const selectedPortfolios = entityFilterSelection.portfolioFilters.filter((filterValue) => filterValue.selected === true) || [];
    const entitiesSelection = [];
    entityFilterSelection.entityFilters.forEach((entity) => {
      if (
        entity.activeDuring.some((activeYear) =>
          reportingYearFilterSelection.reportingYearFilters
            .filter((value) => value.selected === true)
            ?.map((value) => value.returnValue)
            ?.includes(activeYear),
        )
      ) {
        if (selectedBodyTypes.length > 0 || selectedPortfolios.length > 0) {
          const selectedBodyCodenames = selectedBodyTypes.map((bodyTypeOption) => {
            return bodyTypeOption.returnValue;
          });
          const selectedPortfolioCodenames = selectedPortfolios.map((portfolioOption) => portfolioOption.returnValue);

          if (selectedBodyCodenames.includes(entity.bodyType) || entity.portfolio?.some((portfolioCodename) => selectedPortfolioCodenames.includes(portfolioCodename))) {
            entitiesSelection.push({ ...entity, selected: true });
          } else {
            entitiesSelection.push({ ...entity, selected: false });
          }
        } else {
          entitiesSelection.push(entity);
        }
      } else {
        entitiesSelection.push({ ...entity, selected: false });
      }
    });

    // recalculate portfolio and body type selections
    selectedBodyTypes.forEach((bodyType) => {
      selectPortfolioFilterOption(bodyType);
    });

    setEntityFilterSelection({ ...entityFilterSelection, entityFilters: entitiesSelection });
  };

  const clearReportingYearSelection = () => {
    const updatedReportingYears = setSelectedValue([...reportingYearFilterSelection.reportingYearFilters], false);
    setReportingFilterSelection({ reportingYearFilters: updatedReportingYears, selectAllReportingYears: false });
  };

  const atLeastOneReportingYearSelected = () => {
    return reportingYearFilterSelection.reportingYearFilters.some((filterOption) => filterOption.selected === true);
  };

  // Select all options in given list
  const selectAllOptionsInList = (list, previousSelectAllValue) => {
    const updatedOptions = [...list];
    updatedOptions.forEach((option) => {
      option.selected = !previousSelectAllValue;
    });
    return updatedOptions;
  };

  // Updates a dropdown selection option list with updated value from selected option. (Converts 'selected' property to true/false or with a specific override value)
  const updateDropdownSelection = (optionsList, selectedOption, overrideValue) => {
    const updatedSelection = [...optionsList];
    const foundSelectedOption = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    if (foundSelectedOption) {
      foundSelectedOption.selected = overrideValue !== undefined && overrideValue !== null ? overrideValue : !foundSelectedOption.selected;
    }
    return updatedSelection;
  };

  // Selects all body types. Also selects entities relating to those body types
  const selectAllBodyTypesHandler = () => {
    const updatedFilters = selectAllOptionsInList([...entityFilterSelection.bodyTypeFilters], entityFilterSelection.selectAllBodyTypes);
    updatedFilters.forEach((bodyType) => {
      const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.bodyType?.toLowerCase() === bodyType.returnValue?.toLowerCase()) || [];
      selectMultipleEntities(entitiesToSelect, !entityFilterSelection.selectAllBodyTypes);
    });
    setEntityFilterSelection({ ...entityFilterSelection, bodyTypeFilters: updatedFilters, selectAllBodyTypes: !entityFilterSelection.selectAllBodyTypes });
  };

  // Updates the body type filter value. Also selects all entities that contain that body type too.
  const selectBodyTypeFilter = (selectedOption) => {
    const updatedSelection = updateDropdownSelection([...entityFilterSelection.bodyTypeFilters], selectedOption);
    const updatedValue = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.bodyType?.toLowerCase() === selectedOption.returnValue?.toLowerCase()) || [];
    selectMultipleEntities(entitiesToSelect, updatedValue?.selected);
    setEntityFilterSelection({ ...entityFilterSelection, bodyTypeFilters: updatedSelection });
  };

  const allBodyTypesSelected = () => {
    return entityFilterSelection.bodyTypeFilters.every((option) => option.selected === true);
  };

  // Used for displaying of dropdown options selection subtext info
  const numberOfDropdownOptionsSelected = (filterList) => {
    if (filterList.every((option) => option.selected === true)) {
      return 'All';
    }
    return `${filterList.filter((option) => option.selected === true).length}` || '0';
  };

  // Select all portfolios. Also checks all entities relating to those portfolios
  const selectAllPortfoliosHandler = () => {
    const updatedFilters = selectAllOptionsInList([...entityFilterSelection.portfolioFilters], entityFilterSelection.selectAllPortfolios) || [];
    updatedFilters.forEach((portfolio) => {
      const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.portfolio?.includes(portfolio.returnValue)) || [];
      selectMultipleEntities(entitiesToSelect, !entityFilterSelection.selectAllPortfolios);
    });
    setEntityFilterSelection({ ...entityFilterSelection, portfolioFilters: updatedFilters, selectAllPortfolios: !entityFilterSelection.selectAllPortfolios });
  };

  // Function to update portfolio option checkbox. This also updates the entities that contain this portfolio too.
  const selectPortfolioFilterOption = (selectedOption) => {
    const updatedSelection = updateDropdownSelection([...entityFilterSelection.portfolioFilters], selectedOption);
    const updatedValue = updatedSelection.find((option) => option.returnValue === selectedOption.returnValue);
    const entitiesToSelect = filterEntityBySelectedReportingYear()?.filter((option) => option.portfolio?.includes(selectedOption.returnValue)) || [];
    selectMultipleEntities(entitiesToSelect, updatedValue?.selected); // updates all entities checkbox that contain this portfolio
    setEntityFilterSelection({ ...entityFilterSelection, portfolioFilters: updatedSelection });
  };

  const allPortfoliosSelected = () => {
    return entityFilterSelection.portfolioFilters.every((option) => option.selected === true);
  };

  // Selects multiple entitie values from given multi select list
  const selectMultipleEntities = (updatedMultiSelectList, overrideValue) => {
    if (updatedMultiSelectList && Array.isArray(updatedMultiSelectList)) {
      let updatedList = [...entityFilterSelection.entityFilters];
      updatedMultiSelectList.forEach((selectedOption) => {
        updatedList = updateDropdownSelection(updatedList, selectedOption, overrideValue);
      });
      setEntityFilterSelection({ ...entityFilterSelection, entityFilters: updatedList, selectAllEntities: !entityFilterSelection.selectAllEntities });
    }
  };

  // Selects an entity filter option
  const selectEntityOption = (selectedOption) => {
    const updatedSelection = updateDropdownSelection([...entityFilterSelection.entityFilters], selectedOption);
    setEntityFilterSelection({ ...entityFilterSelection, entityFilters: updatedSelection });
  };

  const clearEntitySelection = () => {
    const updatedBodyTypes = setSelectedValue([...entityFilterSelection.bodyTypeFilters], false);
    const updatedPortfolios = setSelectedValue([...entityFilterSelection.portfolioFilters], false);
    const updatedEntities = setSelectedValue([...entityFilterSelection.entityFilters], false);
    setEntityFilterSelection({
      bodyTypeFilters: updatedBodyTypes,
      selectAllBodyTypes: false,
      portfolioFilters: updatedPortfolios,
      selectAllPortfolios: false,
      entityFilters: updatedEntities,
      selectAllEntities: false,
    });
    setShowOnlySelectedEntities(false);
  };

  const allEntitiesSelected = () => {
    return entityFilterSelection.entityFilters.every((filterOption) => filterOption.selected === true);
  };

  const atLeastOneEntitySelected = () => {
    return entityFilterSelection.entityFilters.some((filterOption) => filterOption.selected === true);
  };

  /**
   * ADDITIONAL FILTERS
   *  */
  // Checks what additional filters to display depending on what data sets have been chosen
  const additionalFiltersToDisplay = (additionalFilters, dataSetFiltersSelection) => {
    const filterValues = additionalFilters?.filter((filterGroup) => {
      let filterGroupContainsSelectedDataset = false;
      Object.keys(dataSetFiltersSelection.dataSetFilters).forEach((key) => {
        if (dataSetFiltersSelection.dataSetFilters?.[key]) {
          const foundSelectedDataSet = dataSetFiltersSelection.dataSetFilters?.[key]?.find((dataSetFilter) => {
            return filterGroup?.dataSets?.includes(dataSetFilter?.codename) && dataSetFilter.selected === true;
          });
          if (foundSelectedDataSet) {
            filterGroupContainsSelectedDataset = true;
          }
        }
      });
      return filterGroupContainsSelectedDataset;
    });
    return filterValues || [];
  };

  // Checks which additional filters are allowed for given data set
  const dataSetAdditionalFilters = (dataSetTitle, additionalFilters) => {
    return additionalFilters.filter((filterGroup) => {
      return filterGroup?.dataSets?.includes(dataSetTitle);
    });
  };

  // Function to select additional filter option (main options)
  const selectAdditionalFilter = (selectedOption, selectedFilterGroupTitle) => {
    const specificFilterIndex = additionalFilterSelection.findIndex((filterGroup) => {
      return filterGroup.filterTitle === selectedFilterGroupTitle;
    });
    if (specificFilterIndex !== -1) {
      const updatedSelection = updateDropdownSelection([...additionalFilterSelection[specificFilterIndex].filterOptions], selectedOption);
      const updatedFilters = [...additionalFilterSelection];
      updatedFilters[specificFilterIndex] = { ...updatedFilters[specificFilterIndex], filterOptions: updatedSelection };
      setAdditionalFilterSelection(updatedFilters);
    }
  };

  // APPLIED FILTERS AND MESSAGING

  // Adds reporting years into string to display what reporting years have been selected
  const reportingYearsSelectedText = () => {
    const selectedReportingYears = reportingYearFilterSelection.reportingYearFilters.map((option) => {
      if (option.selected === true) {
        return option.display;
      }
    });
    return selectedReportingYears.filter(Boolean).join(', ');
  };

  // Update selected attribute of object with value to set
  const setSelectedValue = (arrayList, valueToSet) => {
    return arrayList.map((filterValue) => {
      return { ...filterValue, selected: valueToSet };
    });
  };

  // Clears filters, all applied filter and data set tables
  const clearFilters = () => {
    setAppliedFilters({ dataSetFilters: [], reportingYearFilters: [], entityFilters: [] });

    const updatedGroupedDataSets = setSelectedValue([...dataSetFilterSelection.groupedDataSetsFilters], false);
    const updatedDataSets = { ...dataSetFilterSelection.dataSetFilters };
    Object.keys(updatedDataSets).forEach((key) => {
      updatedDataSets[key].forEach((option) => {
        option.selected = false;
      });
    });
    setDataSetFilterSelection({ groupedDataSetsFilters: updatedGroupedDataSets, dataSetFilters: updatedDataSets, dataSetFilterSelectAll: false });

    const updatedBodyTypes = setSelectedValue([...entityFilterSelection.bodyTypeFilters], false);
    const updatedPortfolios = setSelectedValue([...entityFilterSelection.portfolioFilters], false);
    const updatedEntities = setSelectedValue([...entityFilterSelection.entityFilters], false);
    setEntityFilterSelection({
      bodyTypeFilters: updatedBodyTypes,
      selectAllBodyTypes: false,
      portfolioFilters: updatedPortfolios,
      selectAllPortfolios: false,
      entityFilters: updatedEntities,
      selectAllEntities: false,
    });
    setShowOnlySelectedEntities(false);
    setShowOnlySelectedDataSets(false);

    const updatedReportingYears = setSelectedValue([...reportingYearFilterSelection.reportingYearFilters], false);
    const updatedAdditionalFilters = setSelectedValue([...additionalFilterSelection], false);
    setReportingFilterSelection({ reportingYearFilters: updatedReportingYears, selectAllReportingYears: false });
    setAdditionalFilterSelection(updatedAdditionalFilters);

    // reset url params
    setUrlParams((prevParams) => {
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.DATA_SETS}`, '');
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.REPORTING_YEAR}`, '');
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`, '');
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ADDITIONAL}`, '');
      return prevParams;
    });
    // Reset data set tables too if filter is cleared
    setDataSetTables([]);
    setExportIndividualCSVData([]);
    setExpandAllCells([]);
    setNoDataSetData([]);
  };

  /**
   * Fetch data set tables information
   * @param {String} dataSetTitle - Title of data set
   * @param {String} dataSetCodename - Data set codename
   * @param {String} dataSetFilterCodename - Data set filter codename (will help to refer back to additional filters)
   * @param {{display: String, returnValue: String}[]} reportingYears - List of reporting years selected
   * @param {{display: String, returnValue: String}[]} entities - List of entities selected
   * @returns table response
   */
  const fetchDataSetsTables = async (
    dataSetTitle,
    dataSetCodename,
    dataSetFilterCodename,
    reportingYears,
    entities,
    entitiesToExcludeFromMessaging,
    reportingYearsToExcludeFromMessaging,
  ) => {
    let tableResponse = null;
    try {
      setFetchDataSetsLoading(true);

      // prepare values for API call of data sets
      const reportingYearCodenames = reportingYears.map((filterValue) => filterValue.display);
      const entityCodenames = entities.map((filterValue) => filterValue.returnValue);
      const dataSetResponse = await getDataSets(dataSetCodename, reportingYearCodenames, entityCodenames);
      const dataSetTemplateResponse = await getDataTableTemplate(dataSetCodename);

      if (dataSetResponse?.length > 0 && !dataSetResponse.isError && dataSetTemplateResponse && !dataSetTemplateResponse.isError) {
        let dataTable = dataSetResponse;
        if (dataSetTemplateResponse.structure?.type === DATA_TABLE_TYPES.APPEND) {
          dataTable = dataSetResponse.map((dataSet) => {
            return {
              ...dataSet,
              datafields: dataSet.datafields.map((dataField) => {
                return {
                  ...dataField,
                  year: dataSet.reportingPeriod,
                  entity: dataSet.entity,
                };
              }),
            };
          });
        }
        tableResponse = {
          dataSetId: dataSetCodename,
          dataSetFilterId: dataSetFilterCodename,
          dataSetTitle,
          type: dataSetTemplateResponse?.structure?.type,
          defaultValue: dataSetTemplateResponse?.defaultValue,
          dataTemplate: dataSetTemplateResponse?.structure,
          dataTable,
          entities: entities.map((filterValue) => filterValue.display),
          reportingYears: reportingYears.map((filterValue) => filterValue.display),
          entitiesToExcludeFromMessaging: entitiesToExcludeFromMessaging?.map((entity) => entity?.returnValue) || [],
          reportingYearsToExcludeFromMessaging: reportingYearsToExcludeFromMessaging,
        };
      } else {
        tableResponse = {
          dataSetTitle,
          dataSetId: dataSetCodename,
          dataSetFilterId: dataSetFilterCodename,
          entities: entities.map((filterValue) => filterValue.display),
          reportingYears: reportingYears.map((filterValue) => filterValue.display),
          noData: true,
        };
      }
      return tableResponse;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setFetchDataSetsLoading(false);
    }
  };

  /**
   * Updates data template based on additional filters added (allows for filtering out data rows/cols from table)
   * @param {{filterTitle: String, filterSubtext: String, filterOptions: [{display: String, returnValue: string}], dataSets: [String]}[]} additionalFilters - Additional filter list
   * @param {{columns: Array, rows: Array}} templateTable - Template structure for table
   * @returns New template table structure with any rows/columns that were deselected in the additional filters
   */
  const updateAdditionalFilteringInTemplate = (additionalFilters, templateTable) => {
    let allAdditionalFilters = [];
    additionalFilters.forEach((additionalFilter) => {
      allAdditionalFilters = allAdditionalFilters.concat(
        additionalFilter.filterOptions?.map((filterOption) => {
          return { ...filterOption };
        }),
      );
    });
    const additionalFiltersToExclude = allAdditionalFilters.filter((option) => option.selected === false)?.map((option) => option.returnValue) || [];
    const filteredColumns = templateTable?.columns?.filter((col) => {
      return !additionalFiltersToExclude.includes(col.codename);
    });

    const filteredTemplateTable = {
      ...templateTable,
      columns:
        filteredColumns?.map((col) => {
          return {
            ...col,
            subColumns: col.subColumns?.filter((subCol) => !additionalFiltersToExclude.includes(subCol.codename)) || [],
          };
        }) || [],
      rows: templateTable?.rows?.filter((row) => !additionalFiltersToExclude.includes(row.codename)) || [],
    };
    return filteredTemplateTable;
  };

  const updateDataMessaging = (dataSetData) => {
    function sortReportingPeriods(data) {
      // Iterate over each property in the object
      for (const agency in data) {
          // Sort each array by the reportingPeriod
          data[agency].sort((a, b) => {
            const startYearA = parseInt(a.reportingPeriod.split('-')[0]);
            const startYearB = parseInt(b.reportingPeriod.split('-')[0]);
            return startYearA - startYearB;
          });
      }
      return data;
    }

    const entitiesFound = {};
    let entityNames = [];

    const reportingReasonDict = {
      "default": "",
      "never-report": " - There is no data set to display, as this entity or company never has to report on this data set.",
      "expect-from-reporting": " - There is no data set to display, as this entity or company is exempt from reporting on this data set for the selected reporting period. ",
    };

    dataSetData?.dataTable?.forEach((entityYearReponse) => {
      let entityYearReponseReason = {};
      if (entityYearReponse.dataToReport && entityYearReponse.dataToReport != ""){
        entityYearReponseReason = JSON.parse(JSON.parse(entityYearReponse?.dataToReport)?.valueKey); 
      } else {
        return;
      }

      if (entityYearReponseReason?.isDataRequired == "false") {
        if (!entitiesFound[entityYearReponse.entity]) {
          entitiesFound[entityYearReponse.entity] = [{
            'reportingPeriod':entityYearReponse.reportingPeriod,
            'reportingResponce':entityYearReponseReason}
          ];
          entityNames.push(entityYearReponse.entity);
        } else if (!entitiesFound[entityYearReponse.entity].includes(entityYearReponse.reportingPeriod)) {
          entitiesFound[entityYearReponse.entity].push(
            {'reportingPeriod':entityYearReponse.reportingPeriod,
            'reportingResponce':entityYearReponseReason});
        }
      }
    });

    if (Object.keys(entitiesFound).length === 0){
      return;
    }

    const sortedData = sortReportingPeriods(entitiesFound);
    return  (
      <div className={style.entitiesWrapper}>
        {entityNames.map((dataSetItem) => (
          <div key={dataSetItem} className={style.entityItem}>
            <div className={style.titleWrapper}>
              <p><strong>{dataSetItem}</strong></p>
            </div>
            
            <div className={style.listWrapper}>
              <ul>
                {sortedData[dataSetItem].map((reportingYear, index) => (
                  <li key={index}>
                    <strong>{reportingYear.reportingPeriod}</strong> {reportingReasonDict[reportingYear.reportingResponce.selectedReason]}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const updateTableResponseData = async (updatedFilters, additionalFilterList, exportCSV) => {
    const dataSetsData = [];
    const noData = [];
    // Update data set filters to fetch (also fetch linked data sets to a filter)
    const dataSetFiltersToFetch = [];
    const bodyTypesSelected = [];
    const allEntitiySelectionBodyTypes = updatedFilters.entityFilters.map((filter) => filter.bodyType);
    allEntitiySelectionBodyTypes.forEach((bodyType) => {
      if (!bodyTypesSelected.includes(bodyType)) {
        bodyTypesSelected.push(bodyType);
      }
    });

    updatedFilters.dataSetFilters?.forEach((dataFilter) => {
      // For linked data sets, check if reporting years and body types to find if what needs to be included in the request
      if (dataFilter?.linkedDataSet?.length > 0) {
        let bodyTypesOfLinkedFilters = [];
        let reportingYearsOfLinkedFilters = [];
        let linkedDataSetHasBodySelection = false;
        dataFilter?.linkedDataSet.forEach((linkedDataSet) => {
          bodyTypesOfLinkedFilters = bodyTypesOfLinkedFilters.concat(linkedDataSet.bodyTypes);
          reportingYearsOfLinkedFilters = reportingYearsOfLinkedFilters.concat(linkedDataSet.reportingPeriods);
          const hasBodyTypeInSelection = bodyTypesOfLinkedFilters.some((bodyType) => bodyTypesSelected.includes(bodyType));
          if (updatedFilters.reportingYearFilters.some((yearFilter) => linkedDataSet.reportingPeriods.includes(yearFilter.display)) && hasBodyTypeInSelection) {
            linkedDataSetHasBodySelection = true;

            // exclude entities from appearing in the 'About this data' message (based on if linked filter has those body types included)
            let entitiesToExcludeFromMessaging = [];
            dataFilter?.bodyTypes?.forEach((bodyType) => {
              if (!linkedDataSet.bodyTypes.includes(bodyType)) {
                const entityCoveredInSeparateFilter = updatedFilters.entityFilters.filter((entityFilter) => {
                  return entityFilter.bodyType === bodyType;
                });
                entitiesToExcludeFromMessaging = entitiesToExcludeFromMessaging.concat(entityCoveredInSeparateFilter);
              }
            });

            // exclude reporting years from appearing in the 'About this data' message (based on if linked filter has those reporting years included)
            let reportingYearsToExcludeFromMessaging = [];
            dataFilter?.reportingPeriods?.forEach((period) => {
              if (!linkedDataSet?.reportingPeriods?.includes(period)) {
                reportingYearsToExcludeFromMessaging = reportingYearsToExcludeFromMessaging.concat(period);
              }
            });
            dataSetFiltersToFetch.push({ ...linkedDataSet, isLinked: true, entitiesToExcludeFromMessaging, reportingYearsToExcludeFromMessaging });
          }
        });
        const filterContainsBodyTypeSelected = dataFilter.bodyTypes.some((bodyType) => bodyTypesSelected.includes(bodyType));
        if (updatedFilters.reportingYearFilters.some((yearFilter) => dataFilter.reportingPeriods.includes(yearFilter.display)) && filterContainsBodyTypeSelected) {
          let entitiesToExcludeFromMessaging = [];
          bodyTypesOfLinkedFilters.forEach((bodyType) => {
            if (!dataFilter.bodyTypes.includes(bodyType)) {
              // exclude entities from appearing in the 'About this data' message (based on if linked filter has those body types included)
              entitiesToExcludeFromMessaging = entitiesToExcludeFromMessaging.concat(
                updatedFilters.entityFilters.filter((entityFilter) => {
                  return entityFilter.bodyType === bodyType;
                }),
              );
            }
          });
          // exclude reporting years from appearing in the 'About this data' message (based on if linked filter has those reporting years included)
          let reportingYearsToExcludeFromMessaging = [];
          reportingYearsOfLinkedFilters?.forEach((period) => {
            if (!dataFilter.reportingPeriods.includes(period)) {
              reportingYearsToExcludeFromMessaging = reportingYearsToExcludeFromMessaging.concat(period);
            }
          });
          dataSetFiltersToFetch.push({ ...dataFilter, isLinked: true, entitiesToExcludeFromMessaging, reportingYearsToExcludeFromMessaging });
        }

        if (!linkedDataSetHasBodySelection && !filterContainsBodyTypeSelected) {
          dataSetFiltersToFetch.push(dataFilter);
        }
      } else {
        dataSetFiltersToFetch.push(dataFilter);
      }
    });

    for (let i = 0; i < dataSetFiltersToFetch.length; i++) {
      const returnedTables = await fetchDataSetsTables(
        dataSetFiltersToFetch[i].display,
        dataSetFiltersToFetch[i].returnValue,
        dataSetFiltersToFetch[i].codename,
        dataSetFiltersToFetch[i].isLinked
          ? updatedFilters.reportingYearFilters.filter((yearFilter) => dataSetFiltersToFetch[i].reportingPeriods.includes(yearFilter.display))
          : updatedFilters.reportingYearFilters,
        updatedFilters.entityFilters,
        dataSetFiltersToFetch[i].entitiesToExcludeFromMessaging || [],
        dataSetFiltersToFetch[i].reportingYearsToExcludeFromMessaging || [],
      );

      if (!returnedTables.noData) {
        dataSetsData.push(returnedTables);
      } else {
        noData.push(returnedTables);
      }
    }

    if (dataSetsData.length > 0) {
      const updatedDataSets = dataSetsData.filter(Boolean).map((dataSetData) => {
        if (dataSetData.dataTable && dataSetData.dataTemplate) {
          const additionalFilters = JSON.parse(JSON.stringify(dataSetAdditionalFilters(dataSetData?.dataSetFilterId, additionalFilterList) || []));
          const aboutThisData = updateDataMessaging(dataSetData);
          //Filtering out datasets that have no data to declare as false
          const filteredData = dataSetData.dataTable.filter((item)=>{
            //Quick return if data set is empty
            if (item.dataToReport == null || item.dataToReport == '') {
              return true;
            }
            //If there is content it checks if the isDataRequired field is false
            if (item.dataToReport  && item.dataToReport  != ""){
              const declarationData = JSON.parse(JSON.parse(item?.dataToReport)?.valueKey); 
              if (declarationData && declarationData.isDataRequired == "false") {
                return false;
              }
            } 
            return true;
          });


          return {
            ...dataSetData,
            dataTable:filteredData,
            additionalFilters,
            aboutThisData,
            filteredDataTemplate: dataSetData.dataTemplate ? updateAdditionalFilteringInTemplate(additionalFilters, dataSetData.dataTemplate) : [],
          };
        }
        return { ...dataSetData };
      });
      const sortedDataSetData = updatedDataSets?.filter(Boolean).map((dataSetInfo) => {
        if (dataSetInfo.dataTable && dataSetInfo.dataTemplate) {
          if (dataSetInfo?.type === DATA_TABLE_TYPES.FIXED) {
            const sortedDataTable = sortByObjectProperty(dataSetInfo.dataTable, 'entity', 'reportingPeriod', true);
            const annualReports = sortedDataTable.map((entityYearData) => {
              return {
                entity: entityYearData.entity,
                year: entityYearData.reportingPeriod,
                arUrl: entityYearData.annualReportUrl,
                title: entityYearData.annualReportTitle,
              };
            });
            // sort by entity (ascending) then reporting year (descending)
            return {
              ...dataSetInfo,
              dataTable: sortedDataTable,
              annualReports,
            };
          } else if (dataSetInfo?.type === DATA_TABLE_TYPES.APPEND) {
            const sortedDataTable = sortByObjectProperty(dataSetInfo.dataTable, 'reportingPeriod', 'entity', true).reverse();
            const annualReports = sortedDataTable.map((entityYearData) => {
              return {
                entity: entityYearData.entity,
                year: entityYearData.reportingPeriod,
                arUrl: entityYearData.annualReportUrl,
                title: entityYearData.annualReportTitle,
              };
            });
            // sort by reporting year (descending) then entity (ascending)
            return {
              ...dataSetInfo,
              dataTable: sortedDataTable,
              annualReports,
            };
          }
        }
        return {
          ...dataSetInfo,
        };
      });

      if (exportCSV) {
        return sortedDataSetData;
      } else {
        setExpandAllCells(sortedDataSetData.map(() => false));
        setExportIndividualCSVData(
          sortedDataSetData.map(() => {
            return [];
          }),
        );
        setDataSetTables(sortedDataSetData);
      }
    } else {
      setDataSetTables([]);
    }

    if (noData?.length > 0) {
      setNoDataSetData(noData);
    } else {
      setNoDataSetData([]);
    }
  };

  const exportAllCSV = async () => {
    for (let i = 0; i < exportCsvRefs?.current?.length; i++) {
      await exportCSVDownloadButtonRef?.current?.[i]?.click();
    }
  };

  const hasAppliedFilters = () => {
    return appliedFilters.dataSetFilters?.length > 0 && appliedFilters.reportingYearFilters?.length > 0 && appliedFilters.entityFilters?.length > 0;
  };

  const currentSelectionSameFromAppliedFilters = () => {
    const currentDataSetFilters = convertObjectToArray({ ...dataSetFilterSelection.dataSetFilters }).filter((option) => option.selected === true);
    const currentReportingYearFilters = [...reportingYearFilterSelection.reportingYearFilters].filter((option) => option.selected === true);
    const currentEntityFilters = [...entityFilterSelection.entityFilters].filter((option) => option.selected === true);
    let currentAdditionalFilters = [];
    [...additionalFiltersToDisplay(additionalFilterSelection, dataSetFilterSelection)].forEach((additionalGroup) => {
      currentAdditionalFilters = currentAdditionalFilters.concat(additionalGroup.filterOptions.filter((option) => option.selected === false));
    });

    const isDataSetFilterSame =
      appliedFilters.dataSetFilters.length === currentDataSetFilters.length &&
      appliedFilters.dataSetFilters.every((element) => currentDataSetFilters.some((option) => option.returnValue === element.returnValue));
    const isReportingYearFilterSame =
      appliedFilters.reportingYearFilters.length === currentReportingYearFilters.length &&
      appliedFilters.reportingYearFilters.every((element) => currentReportingYearFilters.some((option) => option.returnValue === element.returnValue));
    const isEntityFilterSame =
      appliedFilters.entityFilters.length === currentEntityFilters.length &&
      appliedFilters.dataSetFilters.length === currentDataSetFilters.length &&
      appliedFilters.entityFilters.every((element) => currentEntityFilters.some((option) => option.returnValue === element.returnValue));
    let appliedAdditionalFilters = [];
    [...additionalFiltersToDisplay(appliedFilters.additionalFilters, dataSetFilterSelection)].forEach((additionalGroup) => {
      appliedAdditionalFilters = appliedAdditionalFilters.concat(additionalGroup.filterOptions.filter((option) => option.selected === false));
    });
    const isAdditionalFilterSame =
      currentAdditionalFilters.length === appliedAdditionalFilters.length &&
      currentAdditionalFilters.every((element) => appliedAdditionalFilters.some((option) => option.returnValue === element.returnValue));

    return isDataSetFilterSame && isReportingYearFilterSame && isEntityFilterSame && isAdditionalFilterSame;
  };

  // Apply filters
  const applyFilters = (e) => {
    e.preventDefault();
    // Convert data sets in list to be stored
    const updatedFilters = {
      dataSetFilters: convertObjectToArray({ ...dataSetFilterSelection.dataSetFilters }).filter((option) => option.selected === true),
      reportingYearFilters: [...reportingYearFilterSelection.reportingYearFilters].filter((option) => option.selected === true),
      entityFilters: [...entityFilterSelection.entityFilters].filter((option) => option.selected === true),
      additionalFilters: [...additionalFiltersToDisplay(additionalFilterSelection, dataSetFilterSelection)].map((additionalGroup) => {
        return {
          ...additionalGroup,
          //   since all additional filters are selected as default, this stores only 'unselected' options to reduce number of options added into the url (since there is url limit of 2048 chars)
          filterOptions: structuredClone(additionalGroup.filterOptions.filter((option) => option.selected === false)),
        };
      }),
    };
    setAppliedFilters(updatedFilters);

    // Set url params for applied filters
    setUrlParams((prevParams) => {
      const selectedDataSets = isAllDataSetsFilterSelected()
        ? DATA_SET_URL_CONSTANTS.ALL_DATA_SETS
        : updatedFilters.dataSetFilters
            ?.map((selectedFilter) => {
              return selectedFilter.returnValue;
            })
            ?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.DATA_SETS}`, selectedDataSets || '');

      const selectedReportingYear = updatedFilters.reportingYearFilters
        ?.map((selectedFilter) => {
          return selectedFilter.returnValue;
        })
        ?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.REPORTING_YEAR}`, selectedReportingYear || '');

      const selectedEntities = allEntitiesSelected()
        ? DATA_SET_URL_CONSTANTS.ALL_ENTITIES
        : updatedFilters.entityFilters
            ?.map((selectedFilter) => {
              return selectedFilter.returnValue;
            })
            ?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`, selectedEntities || '');

      // prepares additional filters to be updated into url
      let selectedAdditionalFilters = [];
      updatedFilters.additionalFilters.forEach((additionalFilter) => {
        selectedAdditionalFilters = selectedAdditionalFilters.concat(
          additionalFilter?.filterOptions.map((selectedFilter) => {
            return selectedFilter.returnValue;
          }),
        );
      });

      selectedAdditionalFilters = selectedAdditionalFilters?.join(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY);
      prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ADDITIONAL}`, selectedAdditionalFilters || '');
      return prevParams;
    });

    // Calls api to get response data for tables
    updateTableResponseData(updatedFilters, additionalFilterSelection);
  };

  const filterPortfolioBySelectedReportingYear = () => {
    return entityFilterSelection.portfolioFilters.filter((portfolio) => {
      return portfolio.activeDuring.some((activeYear) =>
        reportingYearFilterSelection.reportingYearFilters
          .filter((value) => value.selected === true)
          ?.map((value) => value.returnValue)
          ?.includes(activeYear),
      );
    });
  };

  const filterEntityBySelectedReportingYear = () => {
    return entityFilterSelection.entityFilters.filter((entity) => {
      return entity.activeDuring.some((activeYear) =>
        reportingYearFilterSelection.reportingYearFilters
          .filter((value) => value.selected === true)
          ?.map((value) => value.returnValue)
          ?.includes(activeYear),
      );
    });
  };

  const filterOnlySelectedEntities = () => {
    return filterEntityBySelectedReportingYear()?.filter((entity) => entity.selected === true) || [];
  };

  // Selects individual filter option of specific table filters (this is filters specific to a tabled data set)
  const selectIndividualAdditionalFilter = (selectedOption, dataSetId, filterGroupTitle) => {
    const dataSetTableToUpdateIndex = dataSetTables.findIndex((dataSet) => {
      return dataSet.dataSetId === dataSetId;
    });
    if (dataSetTableToUpdateIndex !== -1) {
      const additionalFilterToUpdateIndex = dataSetTables[dataSetTableToUpdateIndex].additionalFilters?.findIndex((filterOption) => {
        return filterOption.filterTitle === filterGroupTitle;
      });
      if (additionalFilterToUpdateIndex !== -1) {
        const updatedOptions = updateDropdownSelection(
          [...dataSetTables[dataSetTableToUpdateIndex].additionalFilters[additionalFilterToUpdateIndex].filterOptions],
          selectedOption,
        );
        const updatedAdditionalFilter = [...dataSetTables[dataSetTableToUpdateIndex].additionalFilters];
        updatedAdditionalFilter[additionalFilterToUpdateIndex] = { ...updatedAdditionalFilter[additionalFilterToUpdateIndex], filterOptions: updatedOptions };
        const newDataSets = [...dataSetTables];
        newDataSets[dataSetTableToUpdateIndex] = {
          ...newDataSets[dataSetTableToUpdateIndex],
          filteredDataTemplate: updateAdditionalFilteringInTemplate(updatedAdditionalFilter, newDataSets[dataSetTableToUpdateIndex].dataTemplate),
          additionalFilters: updatedAdditionalFilter,
        };
        setDataSetTables(newDataSets);
      }
    }
  };

  const updateExpandAllCellsState = (index) => {
    const updatedCellState = [...expandAllCells];
    updatedCellState[index] = !updatedCellState[index];
    setExpandAllCells(updatedCellState);
  };

  const downloadCSV = (dataTemplate, dataSetResponse, index, defaultValue) => {
    new Promise((resolve) => {
      constructCSVData(dataTemplate, dataSetResponse, index, defaultValue);
      resolve();
    }).then(() => {
      exportCsvRefs.current[index]?.link.click();
    });
  };

  const constructCSVData = (dataTemplate, dataSetResponse, index, defaultValue) => {
    const updatedState = [...exportIndividualCSVData];

    if (dataTemplate.type === DATA_TABLE_TYPES.FIXED) {
      updatedState[index] = constructCSVFixedTable(dataSetResponse, dataTemplate, defaultValue);
    } else if (dataTemplate.type === DATA_TABLE_TYPES.APPEND) {
      updatedState[index] = constructCSVAppendTable(dataSetResponse, dataTemplate, defaultValue);
    }

    setExportIndividualCSVData(updatedState);
  };

  return (
    <>
      <DataSetsHeader />
      <Suspense fallback={<DataSetsLoading></DataSetsLoading>}>
        <Await resolve={dataSets}>
          {(resolvedData) => {
            if (resolvedData.isError) {
              return <span className={style.failedMessage}>{SOMETHING_WENT_WRONG}</span>;
            }

            const { groupedDataSetsFilters, dataSetFilters, reportingYearFilters, bodyTypeFilters, portfolioFilters, entityFilters, additionalFilters, footnoteData } =
              resolvedData;

            const updateDropdownOptionInUrl = (filterArray, urlOptionsList, setAllTrue, specificProperty) => {
              if (urlOptionsList?.length > 0) {
                filterArray.forEach((dropdownOption) => {
                  if (urlOptionsList.includes(specificProperty ? dropdownOption[specificProperty] : dropdownOption.returnValue) || setAllTrue) {
                    dropdownOption.selected = true;
                  } else {
                    dropdownOption.selected = false;
                  }
                });
              }
            };
            // copy reporting years, body types, portfolios, entities
            const dataSetFiltersCpy = structuredClone(dataSetFilters);
            const reportingYearFiltersCpy = structuredClone(reportingYearFilters);
            const bodyTypeFiltersCpy = structuredClone(bodyTypeFilters);
            const portfolioFiltersCpy = structuredClone(portfolioFilters);
            const entityFiltersCpy = structuredClone(entityFilters);
            const additionalFiltersCpy = structuredClone(additionalFilters);

            useEffect(() => {
              const urlDataSetsOptionsList =
                urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.DATA_SETS}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
              const urlReportingYearOptionsList =
                urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.REPORTING_YEAR}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
              const urlEntityOptionsList =
                urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ENTITY}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
              const urlAdditionalOptionsList =
                urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${DATA_SETS_URL_FILTER_GROUPS.ADDITIONAL}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
              if (groupedDataSetsFilters && dataSetFiltersCpy) {
                const allDataSetsSelected = urlDataSetsOptionsList?.[0] === DATA_SET_URL_CONSTANTS.ALL_DATA_SETS;
                updateDropdownOptionInUrl(convertObjectToArray(dataSetFiltersCpy), allDataSetsSelected ? [''] : urlDataSetsOptionsList, allDataSetsSelected);
                setDataSetFilterSelection({
                  groupedDataSetsFilters,
                  dataSetFilters: dataSetFiltersCpy,
                  dataSetFilterSelectAll: convertObjectToArray(dataSetFiltersCpy).every((option) => option.selected === true),
                });
                setFilteredDataSetList(dataSetFiltersCpy);
              }

              if (reportingYearFiltersCpy) {
                updateDropdownOptionInUrl(reportingYearFiltersCpy, urlReportingYearOptionsList);
                setReportingFilterSelection({ ...reportingYearFilterSelection, reportingYearFilters: reportingYearFiltersCpy });
              }

              if (bodyTypeFilters && portfolioFilters && entityFiltersCpy) {
                const allEntitiesSelected = urlEntityOptionsList?.[0] === DATA_SET_URL_CONSTANTS.ALL_ENTITIES;
                const hasBodyType =
                  urlEntityOptionsList?.includes(BODY_TYPE.CC.VALUE) || urlEntityOptionsList?.includes(BODY_TYPE.CCE.VALUE) || urlEntityOptionsList?.includes(BODY_TYPE.NCE.VALUE);

                updateDropdownOptionInUrl(entityFiltersCpy, allEntitiesSelected ? [''] : urlEntityOptionsList, allEntitiesSelected, hasBodyType ? 'bodyType' : null);
                setEntityFilterSelection({
                  ...entityFilterSelection,
                  bodyTypeFilters: bodyTypeFiltersCpy,
                  portfolioFilters: portfolioFiltersCpy,
                  entityFilters: entityFiltersCpy,
                  selectAllEntities: entityFiltersCpy.every((option) => option.selected === true),
                });
              }

              if (additionalFiltersCpy) {
                // find all additional filters in url and deselects them (since the stored url will store unselected additional filters)
                const additionalFiltersFiltered = additionalFiltersToDisplay(additionalFiltersCpy, { dataSetFilters: dataSetFiltersCpy });
                additionalFiltersFiltered.forEach((additionalFilter) => {
                  additionalFilter?.filterOptions?.forEach((filterOption) => {
                    if (urlAdditionalOptionsList.includes(filterOption?.returnValue)) {
                      filterOption.selected = false;
                    }
                  });
                });
                setAdditionalFilterSelection(additionalFiltersCpy || []);
              }

              // Apply filters and call API for tables if filters have already been applied
              if (urlDataSetsOptionsList.length > 0 && urlReportingYearOptionsList.length > 0 && urlEntityOptionsList.length > 0) {
                const updatedFilters = {
                  dataSetFilters: convertObjectToArray({ ...dataSetFiltersCpy }).filter((option) => option.selected === true),
                  reportingYearFilters: [...reportingYearFiltersCpy].filter((option) => option.selected === true),
                  entityFilters: [...entityFiltersCpy].filter((option) => option.selected === true),
                  additionalFilters: [...additionalFiltersCpy].map((additionalGroup) => {
                    return {
                      ...additionalGroup,
                      //   since all additional filters are selected as default, this stores only 'unselected' options to reduce number of options added into the url (since there is url limit of 2048 chars)
                      filterOptions: structuredClone(additionalGroup.filterOptions.filter((option) => option.selected === false)),
                    };
                  }),
                };
                setAppliedFilters(updatedFilters);

                updateTableResponseData(updatedFilters, additionalFiltersCpy);
              }
            }, [pathname]);

            return (
              <>
                {/* {(!atLeastOneEntitySelected() || !atLeastOneReportingYearSelected() || !isSomeDataSetsFilterSelected()) && (
                  <div className={style.messageBox}>
                    <MessageBox title="Required fields are missing">
                      <p>{requiredFieldsMessage()}</p>
                    </MessageBox>
                  </div>
                )} */}

                <form className={style.filterContainer} onSubmit={(e) => e.preventDefault()}>
                  <DataSetsFilterAccordion title="Data set" isRequired={!isSomeDataSetsFilterSelected()}>
                    <div className={style.dataSetFilter}>
                      <div className={style.groupedDataSets}>
                        <div className={style.titleArea}>
                          <span className={style.filterTitle}>Pre-select grouped data sets.</span>
                          <br></br>
                          <span>Selecting a grouped data set will select every data set in that group in the ‘Select a data set’ field.</span>
                        </div>

                        <div className={style.groupedList}>
                          <ListSelect
                            dropdownOptions={dataSetFilterSelection.groupedDataSetsFilters}
                            isMultiSelect
                            selectOptionFunction={selectGroupedDataSetOption}
                            extraContainerStyleClass={style.groupedContainerList}
                            extraLabelStyleClass={style.groupedListItem}></ListSelect>
                        </div>
                      </div>
                      <div className={style.dataSetList}>
                        <div className={style.titleArea}>
                          <span className={[style.filterTitle, !isSomeDataSetsFilterSelected() && style.isRequired].join(' ')}>Select a data set</span>
                          <br></br>
                          <span>Tags have been used to represent which body types are required to report. </span>
                          <br></br>
                          <span>Source: This data has been provided by Commonwealth entities and companies to represent a subset of their published annual report.</span>
                        </div>
                        <SearchBar
                          placeholder={'Type to search for a data set...'}
                          updateQuery={false}
                          onSubmit={filterDataSetValueSearch}
                          onClear={filterDataSetValueSearch}
                          ariaDescription="On this options list"
                        />
                        <label className={style.checkboxLabel}>
                          <input
                            className={!isAllDataSetsFilterSelected() && dataSetFilterSelection.dataSetFilterSelectAll ? 'dashCheckbox' : ''}
                            type="checkbox"
                            checked={dataSetFilterSelection.dataSetFilterSelectAll}
                            onChange={selectAllDataSetFilters}
                          />
                          Select all shown
                        </label>
                        <div className={style.dataSetFiltersList}>
                          <GroupedFilterList
                            groupedDropdownOptions={showOnlySelectedDataSets ? filterSelectedDataSets() : filteredDataSetList}
                            selectOptionFunction={selectDataSetFilter}
                            extraStyleClass={style.groupedBorder}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={style.entitySelectionControlButtons}>
                      <button className="noFill" onClick={clearDataSetFilters}>
                        Clear
                      </button>
                      <label className={['buttonStyle lightFill', !isSomeDataSetsFilterSelected() && style.disabledAbolishButton].join(' ')}>
                        <input
                          id="showSelectedDataSets"
                          disabled={!isSomeDataSetsFilterSelected()}
                          type="checkbox"
                          checked={showOnlySelectedDataSets}
                          onChange={() => setShowOnlySelectedDataSets(!showOnlySelectedDataSets)}
                        />
                        Show only selected data sets
                      </label>
                    </div>
                  </DataSetsFilterAccordion>
                  <DataSetsFilterAccordion title="Reporting year, entities or companies" isRequired={!atLeastOneReportingYearSelected() || !atLeastOneEntitySelected()}>
                    <div className={[style.reportingYearFilterContainer, !atLeastOneReportingYearSelected() && style.bottomMargin].join(' ')}>
                      <div className={style.reportingYearDropdown}>
                        <FormField
                          title="Selecting reporting years"
                          isRequired={!atLeastOneReportingYearSelected()}
                          showSubText
                          boldedSubText={numberOfDropdownOptionsSelected(reportingYearFilterSelection.reportingYearFilters)}
                          subText="reporting years selected">
                          <DropdownSelect
                            dropdownOptions={reportingYearFilterSelection.reportingYearFilters}
                            selectOptionFunction={selectReportingYearDropdown}
                            isMultiSelect
                            updateDropdownOptionList={atLeastOneYearSelected}
                            renderAsFixed>
                            <div className={style.listSelectSelectAll}>
                              <label htmlFor={'selectAllReportingYears'} className={style.selectAllCheckbox}>
                                <input
                                  checked={reportingYearFilterSelection.selectAllReportingYears}
                                  id={'selectAllReportingYears'}
                                  type={'checkbox'}
                                  onChange={selectAllReportingYears}
                                />
                                Select all
                              </label>
                            </div>
                          </DropdownSelect>
                        </FormField>
                        <button className={['noFill', style.clearButton].join(' ')} onClick={clearReportingYearSelection}>
                          Clear
                        </button>
                      </div>
                    </div>
                    {atLeastOneReportingYearSelected() && (
                      <>
                        <div className={style.entityFilterContainer}>
                          <div className={style.bodyTypePortfolioContainer}>
                            <div className={style.titleArea}>
                              <span className={style.filterTitle}>Select multiple entities or companies by their body type and/or portfolio.</span>
                              <br></br>
                              <span>Selecting a body type and/or a portfolio here will preselect entities or companies in the ‘Select an entity or company’ field.</span>
                            </div>
                            <FormField
                              title="Body type"
                              showSubText
                              boldedSubText={numberOfDropdownOptionsSelected(entityFilterSelection.bodyTypeFilters)}
                              subText="body types selected">
                              <DropdownSelect
                                placeholderText="Select a body type"
                                dropdownOptions={entityFilterSelection.bodyTypeFilters}
                                selectOptionFunction={selectBodyTypeFilter}
                                renderAsFixed
                                isMultiSelect>
                                <div className={style.listSelectSelectAll}>
                                  <label htmlFor={'selectAllBodyType'} className={style.selectAllCheckbox}>
                                    <input
                                      className={!allBodyTypesSelected() && entityFilterSelection.selectAllBodyTypes ? 'dashCheckbox' : ''}
                                      checked={entityFilterSelection.selectAllBodyTypes}
                                      id={'selectAllBodyType'}
                                      type={'checkbox'}
                                      onChange={selectAllBodyTypesHandler}
                                    />
                                    Select all
                                  </label>
                                </div>
                              </DropdownSelect>
                            </FormField>
                            <FormField
                              title="Portfolio"
                              showSubText
                              boldedSubText={numberOfDropdownOptionsSelected(filterPortfolioBySelectedReportingYear())}
                              subText="portfolios selected">
                              <DropdownSelect
                                placeholderText="Type to search for a portfolio..."
                                dropdownOptions={filterPortfolioBySelectedReportingYear()}
                                selectOptionFunction={selectPortfolioFilterOption}
                                renderAsFixed
                                inputEnabled
                                isMultiSelect>
                                <div className={style.listSelectSelectAll}>
                                  <label htmlFor={'selectAllPortfolios'} className={style.selectAllCheckbox}>
                                    <input
                                      className={!allPortfoliosSelected() && entityFilterSelection.selectAllPortfolios ? 'dashCheckbox' : ''}
                                      checked={entityFilterSelection.selectAllPortfolios}
                                      id={'selectAllPortfolios'}
                                      type={'checkbox'}
                                      onChange={selectAllPortfoliosHandler}
                                    />
                                    Select all
                                  </label>
                                </div>
                              </DropdownSelect>
                            </FormField>
                          </div>
                          <div className={style.entityOptionsContainer}>
                            <FormField title="Select an entity or company" isRequired={!atLeastOneEntitySelected()}>
                              <AlphabetGroupFilterList
                                dropdownOptions={showOnlySelectedEntities ? filterOnlySelectedEntities() : filterEntityBySelectedReportingYear()}
                                hideAlphabetButtons
                                selectAllState={entityFilterSelection.selectAllEntities}
                                searchPlaceholder="Type to search for an entity or company..."
                                selectMultipleOptionFunction={selectMultipleEntities}
                                selectOptionFunction={selectEntityOption}
                                extraStyleClass={style.fullWidth}
                                extraListStyleClass={style.groupedListStyle}></AlphabetGroupFilterList>
                            </FormField>
                          </div>
                        </div>
                        <div className={style.entitySelectionControlButtons}>
                          <button className="noFill" onClick={clearEntitySelection}>
                            Clear
                          </button>
                          <label className={['buttonStyle lightFill', !atLeastOneEntitySelected() && style.disabledAbolishButton].join(' ')}>
                            <input
                              id="abolishedButton"
                              disabled={!atLeastOneEntitySelected()}
                              type="checkbox"
                              checked={showOnlySelectedEntities}
                              onChange={() => setShowOnlySelectedEntities(!showOnlySelectedEntities)}
                            />
                            Show only selected entities
                          </label>
                        </div>
                      </>
                    )}
                  </DataSetsFilterAccordion>
                  <DataSetsFilterAccordion
                    title="Additional filters"
                    isDisabled={!isSomeDataSetsFilterSelected() || additionalFiltersToDisplay(additionalFilterSelection, dataSetFilterSelection).length === 0}>
                    <div className={style.additionalFiltersContainer}>
                      <span>
                        These filters will change based on your selection and may not apply to all data sets. You can filter individual data sets by editing ‘Filter this data set.’
                      </span>
                      <div className={style.additionalFilters}>
                        {additionalFiltersToDisplay(additionalFilterSelection, dataSetFilterSelection).map((filterGroup, index) => {
                          return (
                            <FormField
                              key={`${filterGroup}-${index}`}
                              title={filterGroup?.filterTitle}
                              showSubText
                              boldedSubText={numberOfDropdownOptionsSelected(filterGroup.filterOptions)}
                              subText={`${filterGroup.filterSubtext} selected`}>
                              <DropdownSelect
                                isMultiSelect
                                renderAsFixed
                                dropdownOptions={filterGroup.filterOptions}
                                selectOptionFunction={(selectedOption) => selectAdditionalFilter(selectedOption, filterGroup.filterTitle)}
                              />
                            </FormField>
                          );
                        })}
                      </div>
                    </div>
                  </DataSetsFilterAccordion>
                  <div className={style.applyFilters}>
                    <p>
                      Selecting <strong>{numberOfDropdownOptionsSelected(convertObjectToArray(dataSetFilterSelection.dataSetFilters))} data sets</strong> for{' '}
                      <strong>{numberOfDropdownOptionsSelected(filterEntityBySelectedReportingYear())} entities and companies</strong>
                      {reportingYearsSelectedText() && ' between '}
                      <strong>{reportingYearsSelectedText()}</strong>
                    </p>
                    <div className={style.groupedButtons}>
                      <div className={style.filterButtons}>
                        <button onClick={clearFilters} className="noFill">
                          Clear
                        </button>
                        <button disabled={!atLeastOneEntitySelected() || !atLeastOneReportingYearSelected() || !isSomeDataSetsFilterSelected()} onClick={applyFilters}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className={['lightFill', style.exportAllCSV].join(' ')}
                    onClick={exportAllCSV}
                    disabled={
                      !currentSelectionSameFromAppliedFilters() ||
                      !hasAppliedFilters() ||
                      fetchDataSetsLoading ||
                      !dataSetTables?.some((data) => data?.dataTable?.length > 0 && data.dataTemplate)
                    }>
                    Export as CSV
                  </button>
                </form>
                <div>
                  {fetchDataSetsLoading ? (
                    <Loading className={style.loadingDataTables}></Loading>
                  ) : (
                    <>
                      {dataSetTables.map((dataSet, index) => {
                        return (
                          <div key={`${dataSet?.dataSetTitle}-${index}`} className={style.dataSetTableContainer}>
                            <h2>
                              {dataSet.dataSetTitle},{' '}
                              {`${dataSet.reportingYears.length === 1 ? dataSet.reportingYears?.[0] : dataSet.reportingYears.slice(0, -1)?.join(', ')}${
                                dataSet.reportingYears.length > 1 ? ` and ${dataSet.reportingYears.slice(-1)}` : ''
                              }`}
                            </h2>
                            {dataSet.dataTemplate && dataSet?.dataTable?.length > 0 && (
                              <>
                                <div className={style.filterAndTableContainer}>
                                  <div className={style.dataSetInfo}>
                                    {/* Check if data set has additional filters. Only display if necessary */}
                                    {dataSet?.additionalFilters?.length > 0 && (
                                      <DataSetsFilterAccordion title="Filter this data set" styleOverride={style.individualFilterButton} darkTheme>
                                        <div className={style.specificAdditionalFilters}>
                                          {dataSet.additionalFilters.map((filterGroup, index) => {
                                            return (
                                              <FormField
                                                key={`${filterGroup}-${index}`}
                                                title={filterGroup?.filterTitle}
                                                showSubText
                                                boldedSubText={numberOfDropdownOptionsSelected(filterGroup.filterOptions)}
                                                subText={`${filterGroup.filterSubtext} selected`}>
                                                <DropdownSelect
                                                  isMultiSelect
                                                  renderAsFixed
                                                  dropdownOptions={filterGroup.filterOptions}
                                                  selectOptionFunction={(selectedOption) =>
                                                    selectIndividualAdditionalFilter(selectedOption, dataSet.dataSetId, filterGroup.filterTitle)
                                                  }
                                                />
                                              </FormField>
                                            );
                                          })}
                                        </div>
                                      </DataSetsFilterAccordion>
                                    )}
                                    <DataSetsFilterAccordion title="View source" styleOverride={style.individualFilterButton} darkTheme>
                                      <div className={style.annualReportLinksContainer}>
                                        {dataSet?.annualReports?.map((report, index) => {
                                          return (
                                            <Link
                                              key={`${report.title}-${report.entity}-${report.year}-${index}`}
                                              to={`/${PATHS.PUBLICATIONS_LIST.BASE}${report.arUrl || '/:arUrlSlug'}`}
                                              className={['btn', 'lightFill', style.annualReportLink].join(' ')}>
                                              <span className={style.arTitle}>
                                                <strong>{report.title}</strong>
                                              </span>
                                              <span>
                                                {report.entity}, {report.year}
                                              </span>
                                            </Link>
                                          );
                                        })}
                                      </div>
                                    </DataSetsFilterAccordion>
                                    <div className={style.specificFilterButtons}>
                                      {dataSet?.type === DATA_TABLE_TYPES.APPEND && (
                                        <button className={style.expandCellsButton} onClick={() => updateExpandAllCellsState(index)}>
                                          {expandAllCells[index] ? 'Minimise all cells' : 'Expand all cells'}
                                        </button>
                                      )}
                                      <button
                                        ref={(el) => (exportCSVDownloadButtonRef.current[index] = el)}
                                        onClick={() => downloadCSV(dataSet.filteredDataTemplate, dataSet.dataTable, index, dataSet.defaultValue)}>
                                        Export as CSV
                                      </button>
                                      <CSVLink
                                        ref={(el) => (exportCsvRefs.current[index] = el)}
                                        data={exportIndividualCSVData[index]}
                                        uFEFF={false}
                                        filename={`Data set for ${dataSet.dataSetTitle} during ${dataSet.reportingYears.join(' ')}.csv`}></CSVLink>
                                    </div>
                                  </div>
                                  <HorizontalScroll>
                                    <DataSetsTable
                                      webTableData={dataSet?.filteredDataTemplate}
                                      tableData={dataSet?.dataTable}
                                      isFixedTable={dataSet?.type === DATA_TABLE_TYPES.FIXED}
                                      defaultValue={dataSet?.defaultValue}
                                      expandAllCells={expandAllCells[index]}></DataSetsTable>
                                  </HorizontalScroll>
                                  {dataSet.aboutThisData && (
                                      <MessageBox title="About this data" type={MESSAGE_BOX_TYPE.INFORMATION}>
                                        <span tabIndex={0} className={[style.aboutThisData].join(' ')}>
                                          {dataSet.aboutThisData}
                                        </span>
                                      </MessageBox>
                                    )}
                                </div>
                                {dataSet.annotations?.length > 0 && (
                                  <MessageBox title="Annotations" type={MESSAGE_BOX_TYPE.INFORMATION}>
                                    <span tabIndex={0} className={[style.aboutThisData].join(' ')}>
                                      {dataSet.annotations.map((annotation) => {
                                        return (
                                          <div key={annotation.entity} className="entityItem">
                                            <div className="titleWrapper">
                                              <p><strong>{annotation.entity}</strong></p>
                                            </div>
                                            
                                            <div className="listWrapper">
                                              {annotation.content}
                                            </div>
                                        </div>
                                        );
                                      })}
                                    </span>
                                  </MessageBox>
                                )}

                              </>
                            )}  
                          </div>
                        );
                      })}
                      {noDataSetData.length > 0 && (
                        <div className={style.messageBox}>
                          <MessageBox title="This is an in-text error message" type={MESSAGE_BOX_TYPE.WARNING} iconOverride="information">
                              <p>
                                The following entities or companies are not required to report:{' '}
                                <strong>{noDataSetData.map((dataSet) => {
                                return dataSet.entities.join(", ");
                                })}</strong>
                              </p>
                            </MessageBox>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={style.footnoteData}>
                  <sup>{footnoteData}</sup>
                </div>
              </>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}

export default DataSets;
