import React, { Suspense, useEffect, useState } from 'react';
import { Await, Outlet, useLocation, useMatches, useRouteLoaderData } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { Breadcrumb, MainFooter, MainHeader } from 'Components';
import { debounce, innerPageScroll, scrollExists } from 'Utils';
import { IS_FOR_EXPORT_PDF, IS_FOR_EXPORT_WORD, PATHS } from 'Constants';
import icons from 'Assets/svgSprite.svg';
import './App.scss';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';

/**
 * App.jsx
 *
 * @summary This component acts as root route which is wrapper for every
 * routes. It also acts as site layout.
 */
function App() {
  const mainContentId = '#main-content';
  const skipToTopId = 'skip-to-top';
  const { headerFooterData } = useRouteLoaderData(PATHS.HOME.ID);
  const { pathname, hash } = useLocation();
  const [hasScroll, setHasScroll] = useState(scrollExists());
  const matches = useMatches();
  const [applyPrintOnlyStyle] = useState(matches.find((obj) => obj.pathname === pathname)?.handle?.applyPrintStyle || IS_FOR_EXPORT_PDF || IS_FOR_EXPORT_WORD);

  useEffect(() => {
    // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
  }, []);

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setHasScroll(scrollExists()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  }, [pathname]);

  // scroll to top when path change
  useEffect(() => {
    setHasScroll(scrollExists());
    const scrollTargetId = hash?.slice(1); // ignore index 0 as it would always `#`
    const scrollTarget = document.getElementById(scrollTargetId);

    // if scroll target exists
    if (scrollTargetId && scrollTarget) {
      innerPageScroll(scrollTargetId);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pathname, hash]);

  const backToTopBtnClasses = ['btn', 'skipButton', 'toTop'];
  if (!hasScroll) backToTopBtnClasses.push('hidden');

  return (
    <div id="TPApp" className={applyPrintOnlyStyle ? 'printOnly' : null}>
      <HashLink to={mainContentId} className="btn skipButton toContent" title="Skip to Content">
        <span>Skip to content</span>
        <svg>
          <use href={icons + '#arrow'} />
        </svg>
      </HashLink>
      <span id={skipToTopId}></span>
      {(!IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD) && <MainHeader />}
      <main id="main-content">
        {(!IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD) && <Breadcrumb />}
        <Outlet />

        <HashLink to={`#${skipToTopId}`} className={backToTopBtnClasses.join(' ')} title="Back to Top">
          <span>Back to top</span>
          <svg>
            <use href={icons + '#arrow'} />
          </svg>
        </HashLink>
      </main>
      {(!IS_FOR_EXPORT_PDF && !IS_FOR_EXPORT_WORD) && (
        <MainFooter>
          <Suspense>
            <Await resolve={headerFooterData}>
              {(resolvedData) => {
                return (
                  <>
                    {resolvedData?.contentNode}

                    <menu>
                      {resolvedData?.tpPageLinks?.map((link, i) => (
                        <li key={`footer page link ${i}`}>
                          <Link to={link?.url}>{link?.title}</Link>
                        </li>
                      ))}
                      {Array(6 - resolvedData?.tpPageLinks?.length || 0)
                        .fill('fillter')
                        .map((str, i) => (
                          <li key={`${str} ${i}`}>&nbsp;</li>
                        ))}
                    </menu>
                  </>
                );
              }}
            </Await>
          </Suspense>
        </MainFooter>
      )}
    </div>
  );
}

export default App;
